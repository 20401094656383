<template>
  <v-autocomplete
    key="organism-category-select"
    v-model="selection"
    v-bind="$attrs"
    :search-input.sync="search"
    :loading="categoryLoading"
    :items="categories"
    :label="$attrs.label ? $attrs.label : Category"
    item-text="name"
    item-value="id"
    hide-no-data
    cache-items
    return-object
    clearable
    @click:clear="selection = null"
    @change="$emit('update:value', selection)"
  >
    <template
      v-if="prependIcon"
      #prepend
    >
      <v-icon size="20">
        {{ prependIcon }}
      </v-icon>
    </template>
    <template
      v-if="selection.length > 1"
      #selection="{ item }"
    >
      <v-chip
        x-small
        class="ml-0 mb-1"
        v-text="item.name"
      />
    </template>
    <template
      v-if="addCategory"
      #append-outer
    >
      <v-dialog
        v-model="newCategoryDialog"
        width="350"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon small>
              fal fa-plus-circle
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text
            class="pt-2 pb-0"
          >
            <v-text-field
              v-model="newCategoryName"
              label="Category Name"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="newCategoryDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              text
              :loading="newCategoryLoading"
              @click="addCategory"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    results: {
      type: Object,
      default: () => ({}),
    },
    addCategory: {
      type: Boolean,
      default: true,
    },
    prependIcon: {
      type: [String, Boolean],
      default: 'fal fa-folder-open',
    },
  },
  data () {
    return {
      loading: false,
      search: null,
      selection: this.$attrs.value,
      categories: Array.isArray(this.$attrs.value) && !this.$attrs.value.id ? this.$attrs.value : [],
    }
  },
  watch: {
    search (term) {
      if (!this.selection || term != this.selection.name) {
        this.fetchCategories(term)
      }
    },
    '$attrs.value.id' () {
      this.categories.push(this.$attrs.value)
      this.selection = this.$attrs.value
    },
  },
  created () {
    if (!this.categories.length) {
      this.fetchCategories(this.selection?.id ? this.selection.name : null)
    } else {
      this.fetchCategories()
    }
  },
  methods: {
    fetchCategories (search) {
      this.categoryLoading = true
      const promise = this.axios.get('admin/organisms/categories', {
        params:
          {
            count: 20,
            search: search,
          },
      })
      return promise.then((response) => {
        this.categories = response.data.data;
        this.categoryLoading = false;
      }).catch(error => {
        this.$toast.error(error)
      })
    },
  },
}
</script>
